export const environment = {
  production: false,
  stage: '/',
  backend: 'https://api.dev.mobilitycompany.it',
  url_sso: 'https://idam.dev.mobilitycompany.it/auth/welcome',
  url_sso_register: 'https://idam.dev.mobilitycompany.it/auth/register',
  url_sso_recovery: 'https://idam.dev.mobilitycompany.it/auth/password-recovery',
  // googleMapKey: 'AIzaSyBX0MTym4UtIOQMcHxlmT_hCXBGn1deJRg',
  tinymceApiKey: '4c9mn2e6iviq179e8w583hzhrjbg304z7xgxf56lnjkpclqo',
  ambient: 'dev',

};
